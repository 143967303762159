import { isAllOnes } from '../lib/Utils';
import { Uniform } from 'nanogl-pbr/Input';
import { MetalnessSurface } from 'nanogl-pbr/PbrSurface';
export default class PbrMetallicRoughness {
    async parse(gltfLoader, data) {
        var _a, _b;
        this.baseColorFactor = new Float32Array(data.baseColorFactor || [1, 1, 1, 1]);
        this.metallicFactor = (_a = data.metallicFactor) !== null && _a !== void 0 ? _a : 1;
        this.roughnessFactor = (_b = data.roughnessFactor) !== null && _b !== void 0 ? _b : 1;
        if (data.baseColorTexture !== undefined) {
            this.baseColorTexture = await gltfLoader._loadElement(data.baseColorTexture);
        }
        if (data.metallicRoughnessTexture !== undefined) {
            this.metallicRoughnessTexture = await gltfLoader._loadElement(data.metallicRoughnessTexture);
        }
    }
    setupPass(pass) {
        const surface = new MetalnessSurface();
        pass.setSurface(surface);
        if (this.baseColorTexture) {
            const baseColorSampler = this.baseColorTexture.createSampler('basecolor');
            surface.baseColor.attach(baseColorSampler, 'rgb');
            pass.alpha.attach(baseColorSampler, 'a');
        }
        if (!isAllOnes(this.baseColorFactor)) {
            const cFactor = new Uniform('uBasecolorFactor', 4);
            cFactor.set(...this.baseColorFactor);
            surface.baseColorFactor.attach(cFactor, 'rgb');
            pass.alphaFactor.attach(cFactor, 'a');
        }
        if (this.metallicRoughnessTexture) {
            const mrSampler = this.metallicRoughnessTexture.createSampler('metalRough');
            surface.metalness.attach(mrSampler, 'b');
            surface.roughness.attach(mrSampler, 'g');
        }
        if (this.metallicFactor !== 1) {
            surface.metalnessFactor.attachUniform('uMetalnessFactor').set(this.metallicFactor);
        }
        if (this.roughnessFactor !== 1) {
            surface.roughnessFactor.attachUniform('uRoughnessFactor').set(this.roughnessFactor);
        }
    }
}
