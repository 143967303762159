export default class Deferred {
    constructor() {
        this.resolve = (value) => {
            this._resolve(value);
        };
        this.reject = (reason) => {
            this._reject(reason);
        };
        this.promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }
}
